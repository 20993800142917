body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
.element {
  width: 100vw;
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
}

.y-mandatory {
  scroll-snap-type: y mandatory;
}

.parent {
  display: flex;
  flex-wrap: wrap;
}

.child {
  flex-grow: 1;
  width: 33%;
  /* font-size: calc(100px+1vw+1vh); */
}

@media screen and (min-width: 938px) and (max-width: 1200px) {
  .child {
    font-size: 13px;
  }
}

@media screen and (max-height: 680px) {
  .child {
    font-size: 14px;
  }
}


@media screen and (max-height: 570px) {
  .child {
    font-size: 2vh;
  }
}






/* , fontSize: 0.4*(width-00)*4*height/100000 */

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
tr:not(:first-child) {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.show {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Underline styles */
a.menu {
  display: block;
  position: relative;
  padding: 0.2em 0;
}

/* Fade in */
a.menu::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  /* background-color: hotpink; */
  background-color: #e32a46;
  opacity: 0;
  transition: opacity 500ms, transform 500ms;
}

a.menu:hover::after,
a.menu:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}

/* Scale from center */
a.menu::after {
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}

a.menu:hover::after,
a.menu:focus::after {
  transform: scale(1);
}

.loader {
  margin-left: 10px;
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #f3f3f3;
  border-right: 2px solid red;
  border-bottom: 2px solid red;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
